<template>
  <div>
    <GridHelper v-if="config.currentEnv === 'dev'" />
    <Menu v-if="defaultStore.mainMenu" :menu="defaultStore.mainMenu" />
    <slot />

    <FooterContent />
  </div>
</template>

<script setup lang="ts">
import { useDefaultStore } from '~/stores/defaultStore'

const defaultStore = useDefaultStore()
const config = useRuntimeConfig()

useHead({
  bodyAttrs: {
    class: `has-navbar-fixed-top`,
  },
})
</script>

<style lang="scss">
html {
  font-size: 12px;

  @include widescreen {
    font-size: 15px;
  }
}
</style>
